<template>
    <v-card outlined color="primary" class="mt-3">
        <v-card-text>
            <h2 class="text-center mb-4">
                <v-row>
                    <v-col>
                        {{ $t("members.member.history.title") }}
                    </v-col>
                    <v-col
                        cols="auto"
                        class="text-right"
                        v-if="canUpdateHistory"
                    >
                        <v-btn
                            color="success"
                            @click="onCreateHistory()"
                            class="mr-2"
                        >
                            {{ $t("members.member.history.createHistory") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </h2>

            <v-data-table
                class="mt-4"
                :headers="headersHistory"
                :items="history"
                hide-default-footer
                mobile-breakpoint="0"
                :items-per-page="10"
                :page.sync="historyPage"
                @page-count="historyCount = $event"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
            >
                <template v-slot:[`item.date`]="{ item }">
                    {{ getDate(item.date) }}
                </template>
                <template v-slot:[`item.description`]="{ item }">
                    {{ getDescription(item) }}
                </template>
                <template
                    v-slot:[`item.edit`]="{ item }"
                    v-if="canUpdateHistory"
                >
                    <span v-if="canUpdateHistoryLine(item)">
                        <v-icon @click="onUpdateHistoryLine(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon @click="onDeleteHistoryLine(item)">
                            mdi-delete
                        </v-icon>
                    </span>
                </template>
            </v-data-table>
            <v-pagination
                v-model="historyPage"
                :length="historyCount"
            ></v-pagination>
        </v-card-text>

        <member-history-dialog
            v-if="memberHistoryDialog"
            :line="dialogLine !== null ? dialogLine : undefined"
            :memberId="memberId"
            :historyFields="historyFields"
            @close="memberHistoryDialog = false"
            @refresh="refreshHistory()"
        ></member-history-dialog>

        <v-dialog v-model="deleteHistoryDialog" max-width="290">
            <v-card>
                <v-card-title>
                    {{ $t("members.member.history.deleteDialog.title") }}
                </v-card-title>

                <v-card-text v-if="isLoadingDeleteHistory" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("members.member.history.deleteDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else>
                    {{ $t("members.member.history.deleteDialog.text") }}
                    <v-alert v-if="errorDeleteHistory" class="pa-0 error--text">
                        {{ $t("members.member.history.deleteDialog.error") }}
                    </v-alert>
                </v-card-text>

                <v-card-actions v-if="!isLoadingDeleteHistory">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteHistoryLine()">
                        {{ $t("button.yes") }}
                    </v-btn>
                    <v-btn text @click="deleteHistoryDialog = false">
                        {{ $t("button.no") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import MemberHistoryDialog from "@/components/members/MemberHistoryDialog";
import moment from "moment";

export default {
    components: {
        MemberHistoryDialog,
    },

    props: {
        memberId: {
            type: [String, Number],
            required: true,
        },

        history: {
            type: Array,
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },

        canUpdateHistory: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            headersHistory: [
                {
                    text: this.$t("members.member.history.date"),
                    value: "date",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("members.member.history.type"),
                    value: "interaction_type_name",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("members.member.history.description"),
                    value: "description",
                    class: "primary white--text",
                },
            ],
            historyPage: 1,
            historyCount: 0,
            memberHistoryDialog: false,
            dialogLine: null,
            sortBy: "date",
            sortDesc: true,

            deleteHistoryDialog: false,
            isLoadingDeleteHistory: false,
            errorDeleteHistory: false,
        };
    },

    mounted() {
        if (this.canUpdateHistory) {
            this.headersHistory.push({
                text: "",
                value: "edit",
                class: "primary white--text",
                width: "100px",
            });
        }
    },

    methods: {
        getDescription(item) {
            var text = "";
            if (
                item.interaction_type_name == "Participation à un stage fédéral"
            ) {
                text = item.interaction_label_name;
            } else if (
                item.interaction_type_name ==
                "Participation à un stage national"
            ) {
                text =
                    item.interaction_label_name + " - " + item.formation_suivie;
                if (item.certificat_obtenu) {
                    text = text + " - " + item.certificat_obtenu;
                }
            } else if (
                item.interaction_type_name ==
                    "Participation à un orchestre national" ||
                item.interaction_type_name ==
                    "Participation à un orchestre régional"
            ) {
                text = item.type_dorchestre1 + " - " + item.type_daction;
            } else if (
                item.interaction_type_name == "Evaluation individuelle"
            ) {
                text = item.type_devaluation + " - " + item.niveau_passe;
                if (item.niveau_valide) {
                    text = text + " - Validé";
                }
            }
            return text;
        },

        canUpdateHistoryLine(item) {
            var canType = this.historyFields.types.includes(
                item.interaction_type_name
            );
            var canFederation =
                !item.structure_id ||
                this.historyFields.federations.find(
                    (federation) => federation.id == item.structure_id
                );
            return canType && canFederation;
        },

        onCreateHistory() {
            this.dialogLine = null;
            this.memberHistoryDialog = true;
        },

        onUpdateHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.memberHistoryDialog = true;
        },

        onDeleteHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.deleteHistoryDialog = true;
        },

        deleteHistoryLine() {
            this.isLoadingDeleteHistory = true;
            this.errorDeleteHistory = false;

            this.$store
                .dispatch("ohme/deleteMemberHistoryLine", {
                    memberId: this.memberId,
                    historyLineId: this.dialogLine.id,
                })
                .then(() => {
                    this.isLoadingDeleteHistory = false;
                    this.deleteHistoryDialog = false;
                    this.refreshHistory();
                })
                .catch(() => {
                    this.errorDeleteHistory = true;
                    this.isLoadingDeleteHistory = false;
                });
        },

        getDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            }
        },

        refreshHistory() {
            this.memberHistoryDialog = false;
            this.$emit("refresh");
        },
    },
};
</script>
