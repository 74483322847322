<template>
    <v-card outlined color="primary" class="mt-3">
        <v-card-text>
            <h2 class="text-center mb-4">
                <v-row>
                    <v-col>
                        {{ $t("members.member.annualHistory.title") }}
                    </v-col>
                    <v-col cols="auto" class="text-right" v-if="isCFBF">
                        <v-btn
                            color="success"
                            @click="onCreateHistory()"
                            class="mr-2"
                        >
                            {{ $t("members.member.history.createHistory") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </h2>

            <v-data-table
                class="mt-4"
                :headers="headersHistory"
                :items="history"
                hide-default-footer
                mobile-breakpoint="0"
                :items-per-page="5"
                :page.sync="historyPage"
                @page-count="historyCount = $event"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
            >
                <template v-slot:[`item.date`]="{ item }">
                    {{ getDate(item.date) }}
                </template>
                 <template v-slot:[`item.association`]="{ item }">
                     {{getAssociationName(item.structure_id)}}
                 </template>
                <template v-slot:[`item.practice`]="{ item }">
                    <v-chip v-if="item.cours_instrumental" class="ma-1">
                        {{ $t("members.member.annualHistory.instrumental") }}
                    </v-chip>

                    <v-chip v-if="item.cours_formation_musicale" class="ma-1">
                        {{ $t("members.member.annualHistory.musical") }}
                    </v-chip>

                    <v-chip v-if="item.orchestre" class="ma-1">
                        {{ $t("members.member.annualHistory.orchestre") }}
                    </v-chip>

                    <v-chip
                        v-if="item.formation_exterieure_en_ecole_de_musique"
                        class="ma-1"
                    >
                        {{ $t("members.member.annualHistory.formationExte") }}
                    </v-chip>
                </template>
                <template
                    v-slot:[`item.edit`]="{ item }"
                    v-if="canUpdateHistory"
                >
                    <v-icon @click="onUpdateHistoryLine(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon @click="onDeleteHistoryLine(item)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-pagination
                v-model="historyPage"
                :length="historyCount"
            ></v-pagination>
        </v-card-text>

        <member-annual-history-dialog
            v-if="memberHistoryDialog"
            :line="dialogLine !== null ? dialogLine : undefined"
            :memberId="memberId"
            :historyFields="historyFields"
            @close="memberHistoryDialog = false"
            @refresh="refreshHistory()"
        ></member-annual-history-dialog>

        <v-dialog v-model="deleteHistoryDialog" max-width="290">
            <v-card>
                <v-card-title>
                    {{ $t("members.member.history.deleteDialog.title") }}
                </v-card-title>

                <v-card-text v-if="isLoadingDeleteHistory" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("members.member.history.deleteDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else>
                    {{ $t("members.member.history.deleteDialog.text") }}
                    <v-alert v-if="errorDeleteHistory" class="pa-0 error--text">
                        {{ $t("members.member.history.deleteDialog.error") }}
                    </v-alert>
                </v-card-text>

                <v-card-actions v-if="!isLoadingDeleteHistory">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteHistoryLine()">
                        {{ $t("button.yes") }}
                    </v-btn>
                    <v-btn text @click="deleteHistoryDialog = false">
                        {{ $t("button.no") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import MemberAnnualHistoryDialog from "@/components/members/MemberAnnualHistoryDialog";
import moment from "moment";

export default {
    components: {
        MemberAnnualHistoryDialog,
    },

    props: {
        memberId: {
            type: [String, Number],
            required: true,
        },

        history: {
            type: Array,
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },

        canUpdateHistory: {
            type: Boolean,
            required: true,
        },

        isCFBF: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            headersHistory: [
                {
                    text: this.$t("members.member.history.date"),
                    value: "date",
                    class: "primary white--text",
                    sortable: true,
                },
                {
                    text: this.$t("members.member.annualHistory.association"),
                    value: "association",
                    class: "primary white--text",
                    sortable: false,
                },
                 {
                    text: this.$t("members.member.history.instruments"),
                    value: "instruments",
                    class: "primary white--text",
                    sortable: false,
                },
                {
                    text: this.$t("members.member.annualHistory.practice"),
                    value: "practice",
                    class: "primary white--text",
                    sortable: false,
                },
                 {
                    text: this.$t("members.member.annualHistory.instrumentsPretes"),
                    value: "instruments_pretes",
                    class: "primary white--text",
                    sortable: false,
                },
                 {
                    text: this.$t("members.member.annualHistory.tenuesPretees"),
                    value: "tenues_pretees",
                    class: "primary white--text",
                    sortable: false,
                },
            ],
            historyPage: 1,
            historyCount: 0,
            memberHistoryDialog: false,
            dialogLine: null,
            sortBy: "date",
            sortDesc: true,

            deleteHistoryDialog: false,
            isLoadingDeleteHistory: false,
            errorDeleteHistory: false,
        };
    },

    mounted() {
        if (this.canUpdateHistory) {
            this.headersHistory.push({
                text: "",
                value: "edit",
                class: "primary white--text",
                width: "100px",
            });
        }
    },

    methods: {
        onCreateHistory() {
            this.dialogLine = null;
            this.memberHistoryDialog = true;
        },

        onUpdateHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.memberHistoryDialog = true;
        },

        onDeleteHistoryLine(historyLine) {
            this.dialogLine = Object.assign({}, historyLine);
            this.deleteHistoryDialog = true;
        },

        deleteHistoryLine() {
            this.isLoadingDeleteHistory = true;
            this.errorDeleteHistory = false;

            this.$store
                .dispatch("ohme/deleteMemberAnnualHistoryLine", {
                    memberId: this.memberId,
                    historyLineId: this.dialogLine.id,
                })
                .then(() => {
                    this.isLoadingDeleteHistory = false;
                    this.deleteHistoryDialog = false;
                    this.refreshHistory();
                })
                .catch(() => {
                    this.errorDeleteHistory = true;
                    this.isLoadingDeleteHistory = false;
                });
        },

        getDate(date) {
            if (date) {
                return moment(date).format("YYYY");
            }
        },

        getAssociationName(associationId){
            var association = this.historyFields.associations.find(element => element.id == associationId);
            if(association){
                return association.name;
            }
        },

        refreshHistory() {
            this.memberHistoryDialog = false;
            this.$emit("refresh");
        },
    },
};
</script>
