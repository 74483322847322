<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="members.showError"
    >
        <h1 class="text-center mb-4">
            {{ member.firstname }} {{ member.lastname }}
            <v-btn
                v-if="canUpdate"
                text
                :to="{
                    name: 'UpdateMember',
                    params: {
                        memberId: memberId,
                    },
                }"
            >
                <v-icon small color="primary">
                    mdi-pencil
                </v-icon>
            </v-btn>
        </h1>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <v-row class="mb-2">
                    <v-col>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense>mdi-at</v-icon>
                                        {{ $t("members.member.email") }}
                                        :
                                    </strong>
                                    {{ member.email }}
                                </p>
                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense>mdi-phone</v-icon>
                                        {{ $t("members.member.phone") }}
                                        :
                                    </strong>
                                    {{ member.phone }}
                                </p>
                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense>
                                            mdi-mailbox-open-outline
                                        </v-icon>
                                        {{ $t("members.member.address.all") }}
                                        :
                                    </strong>
                                    <span v-if="member.address">
                                        {{ member.address.street }}
                                        {{ member.address.street_2 }}
                                        {{ member.address.post_code }}
                                        {{ member.address.city }}
                                    </span>
                                </p>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense>mdi-cake-variant</v-icon>
                                        {{ $t("members.member.birthDate") }}
                                        :
                                    </strong>
                                    {{ getDate(this.member.birthdate) }}
                                </p>

                                <p class="mb-0">
                                    <strong>
                                        <v-icon dense
                                            >mdi-music-clef-treble</v-icon
                                        >
                                        {{ $t("members.member.instruments") }}
                                        :
                                    </strong>
                                    <span v-if="member.instrument_principal">
                                        {{ member.instrument_principal.join(", ") }}
                                    </span>
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="auto">
                        <v-chip
                            color="primary"
                            class="ma-1"
                            v-if="haveEvaluation('3eme cycle - CRPI')"
                        >
                            {{ $t("members.member.CRPI") }}
                        </v-chip>
                        <v-chip
                            color="primary"
                            class="ma-1"
                            v-if="haveEvaluation('3eme cycle - CNPI')"
                        >
                            {{ $t("members.member.CNPI") }}
                        </v-chip>
                    </v-col>
                </v-row>

                <div
                    class="mb-0"
                    v-if="
                        typeof member.associations !== 'undefined' &&
                            member.associations.length !== 0
                    "
                >
                    <p class="text-center mb-0 font-weight-black">
                        {{ $t("members.member.associations") }}
                        :
                    </p>
                    <v-card
                        v-for="(association,
                        associationId) in member.associations"
                        :key="associationId"
                        outlined
                        class="mt-2"
                    >
                        <v-card-title class="pb-0">
                            <router-link
                                class="mr-2 primary--text text-decoration-none"
                                :to="{
                                    name: 'Association',
                                    params: {
                                        associationId: associationId,
                                    },
                                }"
                            >
                                {{ association.nom }}
                            </router-link>
                            ({{ association.année }})

                            <v-spacer></v-spacer>

                            <v-btn
                                v-if="canUpdate"
                                icon
                                @click="
                                    onUpdateAssociation(
                                        associationId,
                                        association
                                    )
                                "
                            >
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <strong>
                                <v-icon dense>
                                    mdi-badge-account-horizontal
                                </v-icon>
                                {{ $t("members.member.positions") }} :</strong
                            >
                            {{ association.fonctions.join(", ") }} <br />
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
        </v-card>

        <v-dialog v-model="updateAssociationDialog" max-width="500">
            <v-card>
                <v-card-title>
                    {{ selectedUpdate.nom }}
                </v-card-title>

                <v-card-text
                    v-if="isLoadingUpdateAssociation"
                    class="text-center"
                >
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("members.member.updateAssociationDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else>
                    <v-autocomplete
                        chips
                        deletable-chips
                        multiple
                        hide-details
                        v-model="selectedUpdate.fonctions"
                        :label="$t('members.member.positions')"
                        :items="positions"
                    ></v-autocomplete>
                    {{ $t("members.member.boardUpdateOnAssociation") }}

                    <v-alert
                        v-if="errorUpdateAssociation"
                        class="pa-0 error--text"
                    >
                        {{ $t("members.member.updateAssociationDialog.error") }}
                    </v-alert>
                </v-card-text>

                <v-card-actions v-if="!isLoadingUpdateAssociation">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="updateAssociation()">
                        {{ $t("button.save") }}
                    </v-btn>
                    <v-btn text @click="updateAssociationDialog = false">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row class="py-4">
            <v-col cols="12" xl="6" v-if="canUpdate" class="py-0">
                <member-annual-history
                    v-if="!isLoadingAnnualHistoryFields"
                    :memberId="memberId"
                    :history="annualHistory"
                    :historyFields="annualHistoryFields"
                    :canUpdateHistory="canUpdate"
                    :isCFBF="isCFBF"
                    @refresh="getMember()"
                >
                </member-annual-history>
            </v-col>
            <v-col cols="12" :xl="canUpdate ? 6 : 12" class="py-0">
                <member-history
                    v-if="!isLoadingHistoryFields"
                    :memberId="memberId"
                    :history="history"
                    :historyFields="historyFields"
                    :canUpdateHistory="canUpdateHistory"
                    @refresh="getMember()"
                >
                </member-history>
            </v-col>
        </v-row>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Loader from "@/components/utils/Loader";
import MemberHistory from "@/components/members/MemberHistory";
import MemberAnnualHistory from "@/components/members/MemberAnnualHistory";

export default {
    components: {
        Loader,
        MemberHistory,
        MemberAnnualHistory,
    },

    props: {
        memberId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            member: {},
            history: [],
            canUpdate: false, //Peut également modifier l'historique annuel
            canUpdateHistory: false,
            isCFBF: false,

            isLoadingMember: true,
            errorMember: false,

            isLoadingHistoryFields: true,
            errorHistoryFields: false,
            historyFields: {},

            isLoadingAnnualHistoryFields: true,
            errorAnnualHistoryFields: false,
            annualHistoryFields: {},

            instruments: [],
            positions: [],

            selectedUpdate: {},
            updateAssociationDialog: false,
            isLoadingUpdateAssociation: false,
            errorUpdateAssociation: false,
        };
    },

    mounted() {
        this.getMember();
    },

    computed: {
        ...mapState({
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
        }),

        isLoading() {
            return (
                this.isLoadingMember ||
                this.isLoadingHistoryFields ||
                this.isLoadingAnnualHistoryFields
            );
        },

        error() {
            return (
                this.errorMember ||
                this.errorHistoryFields ||
                this.errorAnnualHistoryFields
            );
        },
    },

    methods: {
        getMember() {
            this.isLoadingMember = true;
            this.errorMember = false;

            this.$store
                .dispatch("ohme/getMember", this.memberId)
                .then((data) => {
                    this.canUpdateHistory = data.data.canUpdateHistory;
                    this.canUpdate = data.data.canUpdate;
                    this.getCreateHistoryFields();
                    this.getCreateAnnualHistoryFields();

                    this.member = data.data.member;
                    this.isCFBF = data.data.isCFBF;
                    this.history = data.data.history;
                    this.annualHistory = data.data.annualHistory;
                    this.isLoadingMember = false;

                    this.instruments = data.data.instruments;
                    this.positions = data.data.positions;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 404
                    ) {
                        this.$router.push({ name: "404" });
                    } else {
                        this.isLoadingMember = false;
                        this.errorMember = true;
                    }
                });
        },

        getCreateHistoryFields() {
            if (this.canUpdateHistory) {
                this.isLoadingHistoryFields = true;
                this.errorHistoryFields = false;

                this.$store
                    .dispatch("ohme/getCreateMemberHistoryLine", this.memberId)
                    .then((data) => {
                        this.historyFields = data.data;
                        this.isLoadingHistoryFields = false;
                    })
                    .catch(() => {
                        this.isLoadingHistoryFields = false;
                        this.errorHistoryFields = true;
                    });
            } else {
                this.historyFields = {};
                this.isLoadingHistoryFields = false;
                this.errorHistoryFields = false;
            }
        },

        getCreateAnnualHistoryFields() {
            if (this.canUpdate) {
                this.isLoadingAnnualHistoryFields = true;
                this.errorAnnualHistoryFields = false;

                this.$store
                    .dispatch(
                        "ohme/getCreateMemberAnnualHistoryLine",
                        this.memberId
                    )
                    .then((data) => {
                        this.annualHistoryFields = data.data;
                        this.isLoadingAnnualHistoryFields = false;
                    })
                    .catch(() => {
                        this.isLoadingAnnualHistoryFields = false;
                        this.errorAnnualHistoryFields = true;
                    });
            } else {
                this.annualHistoryFields = {};
                this.isLoadingAnnualHistoryFields = false;
                this.errorAnnualHistoryFields = false;
            }
        },

        onUpdateAssociation(associationId, association) {
            this.selectedUpdate = Object.assign({}, association);
            this.selectedUpdate.associationId = associationId;
            this.updateAssociationDialog = true;
        },

        updateAssociation() {
            this.isLoadingUpdateAssociation = true;
            this.errorUpdateAssociation = false;

            var form = {
                positions: this.selectedUpdate.fonctions,
            };

            this.$store
                .dispatch("ohme/updateMemberAssociationData", {
                    memberId: this.memberId,
                    associationId: this.selectedUpdate.associationId,
                    form: form,
                })
                .then(() => {
                    this.isLoadingUpdateAssociation = false;
                    this.updateAssociationDialog = false;
                    this.getMember();
                })
                .catch(() => {
                    this.isLoadingUpdateAssociation = false;
                    this.errorUpdateAssociation = true;
                });
        },

        getDate(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY");
            }
        },

        haveEvaluation(evaluationName) {
            return this.history.find(
                (item) =>
                    item.interaction_type_name == "Evaluation individuelle" &&
                    item.niveau_passe == evaluationName &&
                    item.niveau_valide
            );
        },
    },
};
</script>
