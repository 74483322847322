<template>
    <v-dialog :value="true" max-width="800" persistent>
        <v-card>
            <v-form ref="form" @submit.prevent="onCreate">
                <v-card-text v-if="isLoading" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("members.member.historyDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else-if="error" class="text-center">
                    <v-alert class="pa-0 error--text">
                        {{ $t("members.member.historyDialog.error") }}
                    </v-alert>
                </v-card-text>
                <v-card-text v-else>
                    <h1 class="text-center my-2">
                        {{ $t("members.member.annualHistoryDialog.title") }}
                    </h1>
                    <v-text-field
                        v-model="newLine.date"
                        :label="$t('members.member.history.date') + '*'"
                        :rules="requiredYearRule"
                        type="number"
                        :disabled="!!line"
                    ></v-text-field>

                    <v-select
                        v-if="historyFields.associations.length > 0"
                        v-model="newLine.structure_id"
                        :label="
                            $t('members.member.annualHistory.association') + '*'
                        "
                        item-text="name"
                        item-value="id"
                        :rules="requiredSelectRule"
                        :items="historyFields.associations"
                        :disabled="!!line"
                    >
                    </v-select>

                    <v-select
                        v-model="newLine.instruments"
                        :label="$t('members.member.history.instruments') + '*'"
                        :rules="requiredSelectRule"
                        multiple
                        :items="historyFields.instruments"
                    >
                    </v-select>

                    <v-select
                        v-model="newLine.cours_instrumental"
                        :label="
                            $t('members.member.annualHistory.instrumental') +
                                '*'
                        "
                        :rules="requiredBooleanSelectRule"
                        :items="[
                            { text: $t('button.yes'), value: true },
                            { text: $t('button.no'), value: false },
                        ]"
                    >
                    </v-select>

                    <v-select
                        v-model="newLine.cours_formation_musicale"
                        :label="
                            $t('members.member.annualHistory.musical') + '*'
                        "
                        :rules="requiredBooleanSelectRule"
                        :items="[
                            { text: $t('button.yes'), value: true },
                            { text: $t('button.no'), value: false },
                        ]"
                    >
                    </v-select>

                    <v-select
                        v-model="newLine.orchestre"
                        :label="
                            $t('members.member.annualHistory.orchestre') + '*'
                        "
                        :rules="requiredBooleanSelectRule"
                        :items="[
                            { text: $t('button.yes'), value: true },
                            { text: $t('button.no'), value: false },
                        ]"
                    >
                    </v-select>

                    <v-select
                        v-model="
                            newLine.formation_exterieure_en_ecole_de_musique
                        "
                        :label="
                            $t('members.member.annualHistory.formationExte') +
                                '*'
                        "
                        :rules="requiredBooleanSelectRule"
                        :items="[
                            { text: $t('button.yes'), value: true },
                            { text: $t('button.no'), value: false },
                        ]"
                    >
                    </v-select>

                    <v-textarea
                        v-model="newLine.instruments_pretes"
                        rows="2"
                        :label="
                            $t('members.member.annualHistory.instrumentsPretes')
                        "
                    ></v-textarea>
                     <v-textarea
                        v-model="newLine.tenues_pretees"
                        rows="2"
                        :label="
                            $t('members.member.annualHistory.tenuesPretees')
                        "
                    ></v-textarea>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="this.line !== undefined"
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.update") }}
                    </v-btn>
                    <v-btn
                        v-else
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.add") }}
                    </v-btn>
                    <v-btn @click="onClose">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
    props: {
        line: {
            type: Object,
        },

        memberId: {
            type: [String, Number],
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            newLine: this.line,

            isLoading: false,
            error: false,
        };
    },

    created() {
        if (this.newLine == undefined) {
            this.newLine = {
                date: moment().format("YYYY"),
                instruments: null,
                structure_id: null,
                cours_instrumental: null,
                cours_formation_musicale: null,
                orchestre: null,
                formation_exterieure_en_ecole_de_musique: null,
                instruments_pretes: null,
                tenues_pretees: null,
            };
        } else {
            this.newLine.date = moment(this.newLine.date).format("YYYY");
        }
    },

    mounted() {
        if (this.line !== undefined) {
            this.$refs.form.validate();
        }
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
            requiredBooleanSelectRule: (state) =>
                state.utils.requiredBooleanSelectRule,
            requiredYearRule: (state) => state.utils.requiredYearRule,
        }),
    },

    methods: {
        onCreate() {
            if (
                this.$refs.form.validate() &&
                (this.newLine.interaction_type_name !==
                    "Evaluation individuelle" ||
                    this.newLine.type_devaluation !== "Instrumentale" ||
                    (this.newLine.cursus !== "Mixte" &&
                        this.newLine.instruments.length == 1) ||
                    (this.newLine.cursus == "Mixte" &&
                        this.newLine.instruments.length == 2))
            ) {
                if (this.line !== undefined) {
                    this.updateHistoryLine();
                } else {
                    this.createHistoryLine();
                }
            }
        },

        createHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/createMemberAnnualHistoryLine", {
                    memberId: this.memberId,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        updateHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/updateMemberAnnualHistoryLine", {
                    memberId: this.memberId,
                    historyLineId: this.line.id,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        onClose() {
            this.$emit("close");
        },
    },
};
</script>
