<template>
    <v-dialog :value="true" max-width="800" persistent>
        <v-card>
            <v-form ref="form" @submit.prevent="onCreate">
                <v-card-text v-if="isLoading" class="text-center">
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                    <br />
                    {{ $t("members.member.historyDialog.isLoading") }}
                </v-card-text>
                <v-card-text v-else-if="error" class="text-center">
                    <v-alert class="pa-0 error--text">
                        {{ $t("members.member.historyDialog.error") }}
                    </v-alert>
                </v-card-text>
                <v-card-text v-else>
                    <h1 class="text-center my-2">
                        {{ $t("members.member.historyDialog.title") }}
                    </h1>
                    <v-select
                        v-model="newLine.interaction_type_name"
                        :label="$t('members.member.history.type') + '*'"
                        :rules="requiredSelectRule"
                        :items="historyFields.types"
                        :disabled="!!line"
                    >
                    </v-select>

                    <div v-if="newLine.interaction_type_name">
                        <!-- Commun à tous les types -->
                        <v-text-field
                            v-model="newLine.date"
                            :label="$t('members.member.history.date') + '*'"
                            :rules="requiredRule"
                            type="date"
                        ></v-text-field>

                        <!-- Uniquement pour les stages -->
                        <v-text-field
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un stage fédéral' ||
                                    newLine.interaction_type_name ==
                                        'Participation à un stage national'
                            "
                            v-model="newLine.interaction_label_name"
                            :label="$t('members.member.history.label') + '*'"
                            :rules="requiredRule"
                        ></v-text-field>

                        <!-- Uniquement pour les stages nationaux -->
                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un stage national'
                            "
                            v-model="newLine.formation_suivie"
                            :label="
                                $t('members.member.history.formation') + '*'
                            "
                            :rules="requiredSelectRule"
                            :items="historyFields.formations"
                        >
                        </v-select>

                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un stage national'
                            "
                            v-model="newLine.certificat_obtenu"
                            :label="$t('members.member.history.certificate')"
                            :items="historyFields.certificates"
                        >
                        </v-select>

                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un stage national'
                            "
                            v-model="newLine.uv_validees"
                            :label="$t('members.member.history.UV') + '*'"
                            clearable
                            :items="historyFields.UV"
                            multiple
                        >
                        </v-select>

                        <!-- Uniquement pour les orchestre -->
                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un orchestre national' ||
                                    newLine.interaction_type_name ==
                                        'Participation à un orchestre régional'
                            "
                            v-model="newLine.type_dorchestre1"
                            :label="
                                $t('members.member.history.orchestraType') + '*'
                            "
                            :rules="requiredSelectRule"
                            :items="historyFields.orchestraTypes"
                        >
                        </v-select>

                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un orchestre national' ||
                                    newLine.interaction_type_name ==
                                        'Participation à un orchestre régional'
                            "
                            v-model="newLine.type_daction"
                            :label="$t('members.member.history.action') + '*'"
                            :rules="requiredSelectRule"
                            :items="historyFields.actionTypes"
                        >
                        </v-select>

                        <!-- Uniquement pour les orchestre régionaux ou stage fédéral et pour la création-->
                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Participation à un orchestre régional' ||
                                    newLine.interaction_type_name ==
                                        'Participation à un stage fédéral'
                            "
                            v-model="newLine.structure_id"
                            :label="
                                $t('members.member.history.federation') + '*'
                            "
                            item-text="name"
                            item-value="id"
                            :rules="requiredSelectRule"
                            :items="historyFields.federations"
                            :disabled="!!line"
                        >
                        </v-select>

                        <!-- Uniquement pour les évaluations -->
                        <v-select
                            v-if="
                                newLine.interaction_type_name ==
                                    'Evaluation individuelle'
                            "
                            v-model="newLine.type_devaluation"
                            :label="
                                $t('members.member.history.evaluationType') +
                                    '*'
                            "
                            :rules="requiredSelectRule"
                            :items="historyFields.evaluationTypes"
                        >
                        </v-select>

                        <!-- Uniquement pour les évaluations instrumentales -->
                        <div
                            v-if="
                                newLine.interaction_type_name ==
                                    'Evaluation individuelle' &&
                                    newLine.type_devaluation == 'Instrumentale'
                            "
                        >
                            <v-select
                                v-model="newLine.cursus"
                                :label="
                                    $t('members.member.history.cursus') + '*'
                                "
                                :rules="requiredSelectRule"
                                :items="historyFields.cursus"
                            >
                            </v-select>

                            <v-select
                                v-model="newLine.instruments"
                                :label="
                                    $t('members.member.history.instruments') +
                                        '*'
                                "
                                :rules="requiredSelectRule"
                                multiple
                                :counter="newLine.cursus == 'Mixte' ? 2 : 1"
                                :items="historyFields.instruments"
                            >
                            </v-select>
                        </div>

                        <!-- Uniquement pour les évaluations -->
                        <div
                            v-if="
                                newLine.interaction_type_name ==
                                    'Evaluation individuelle' &&
                                    !!newLine.type_devaluation
                            "
                        >
                            <v-select
                                v-model="newLine.niveau_passe"
                                :label="
                                    $t('members.member.history.level') + '*'
                                "
                                :rules="requiredSelectRule"
                                :items="historyFields.levels"
                            >
                            </v-select>

                            <v-select
                                v-model="newLine.mention_obtenue_evaluation"
                                :label="
                                    $t('members.member.history.mention') + '*'
                                "
                                clearable
                                :items="historyFields.mentions"
                                :rules="requiredSelectRule"
                            >
                            </v-select>

                            <v-select
                                v-model="newLine.niveau_valide"
                                :label="
                                    $t('members.member.history.validate') + '*'
                                "
                                :rules="requiredBooleanSelectRule"
                                :items="[
                                    { text: $t('button.yes'), value: true },
                                    { text: $t('button.no'), value: false },
                                ]"
                            >
                            </v-select>
                        </div>

                        <!-- Commun à tous les types -->
                        <v-textarea
                            v-model="newLine.comment"
                            rows="2"
                            :label="$t('members.member.history.comment')"
                        ></v-textarea>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="this.line !== undefined"
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.update") }}
                    </v-btn>
                    <v-btn
                        v-else
                        color="primary"
                        type="submit"
                        :loading="isLoading"
                    >
                        {{ $t("button.add") }}
                    </v-btn>
                    <v-btn @click="onClose">
                        {{ $t("button.return") }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
    props: {
        line: {
            type: Object,
        },

        memberId: {
            type: [String, Number],
            required: true,
        },

        historyFields: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            newLine: this.line,

            isLoading: false,
            error: false,
        };
    },

    created() {
        if (this.newLine == undefined) {
            this.newLine = {
                interaction_type_name: null,
                date: moment().format("YYYY-MM-DD"),
                interaction_label_name: null,
                formation_suivie: null,
                certificat_obtenu: null,
                type_dorchestre1: null,
                type_daction: null,
                type_devaluation: null,
                cursus: null,
                instruments: null,
                niveau_passe: null,
                mention_obtenue_evaluation: null,
                niveau_valide: 1,
                structure_id: null,
                comment: null,
            };
            if (this.historyFields.types.length == 1) {
                this.newLine.interaction_type_name = this.historyFields.types[0];
            }
        } else {
            this.newLine.date = moment(this.newLine.date).format("YYYY-MM-DD");
        }
    },

    mounted() {
        if (this.line !== undefined) {
            this.$refs.form.validate();
        }
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            requiredSelectRule: (state) => state.utils.requiredSelectRule,
            requiredBooleanSelectRule: (state) =>
                state.utils.requiredBooleanSelectRule,
        }),
    },

    methods: {
        onCreate() {
            if (
                this.$refs.form.validate() &&
                (this.newLine.interaction_type_name !==
                    "Evaluation individuelle" ||
                    this.newLine.type_devaluation !== "Instrumentale" ||
                    (this.newLine.cursus !== "Mixte" &&
                        this.newLine.instruments.length == 1) ||
                    (this.newLine.cursus == "Mixte" &&
                        this.newLine.instruments.length == 2))
            ) {
                if (this.line !== undefined) {
                    this.updateHistoryLine();
                } else {
                    this.createHistoryLine();
                }
            }
        },

        createHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/createMemberHistoryLine", {
                    memberId: this.memberId,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        updateHistoryLine() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/updateMemberHistoryLine", {
                    memberId: this.memberId,
                    historyLineId: this.line.id,
                    form: this.newLine,
                })
                .then(() => {
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isLoading = false;
                    this.error = true;
                });
        },

        onClose() {
            this.$emit("close");
        },
    },
};
</script>
